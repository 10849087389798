<!--
   User: cyx
   Date: 2024/2/23
   Description:名师介绍详情
 -->
<template>
  <div class="bodydiv">
    <div class="container">
      <el-card class="box-card title-card">
        <div style="display: flex;align-items: center;">
          <el-breadcrumb separator-class="el-icon-arrow-right" class="curpoin">
            <el-breadcrumb-item><span class="curpoin" @click="goBack()">企业学院</span></el-breadcrumb-item>
            <el-breadcrumb-item>名师介绍</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-card>
      <div class="course-head">
        <div class="course-info">
          <img class="course-avatar" :src="getImage(itemTeacher)" @error="handleError">
          <div class="introduce">
            <p class="course-name">{{ itemTeacher.name }}</p>
            <p class="course-releasetime">{{ itemTeacher.tags }}</p>
            <!-- <p class="course-releasetime">联系方式:{{ itemTeacher.mobile }}</p> -->
            <div style="display:flex">
              <p class="course-describe-title">擅长科目:</p>
              <p class="course-releasetime" style="flex: 1;" v-html="itemTeacher.subject"></p>
            </div>
            <div style="display:flex">
              <p class="course-describe-title">教师简介:</p>
              <p class="course-releasetime" style="flex: 1;" v-html="itemTeacher.introduction"></p>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import imageTop from '@/assets/images/appraise-top.png'
export default {
  components: {

  },
  props: {
    itemTeacher: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
    }
  },
  mounted() {
    document.body.scrollTop = 0
  },
  methods: {
    getImage(item) {
      if (item.avatar) return item.avatar
      return imageTop
    },
    // 图片链接失效/错误给默认图
    handleError(event) {
      const img = event.srcElement
      img.src = imageTop
      img.onerror = null // 防止闪图
    },
    goBack() {
      this.$emit('close')
    }
  }

}
</script>

<style lang="scss" scoped>
.bodydiv {
  width: 100%;
  background: #f6f6f8;
  padding: 10px 0;
  //     height: 100%;
  // overflow: auto;
}
.container {
  width: 1360px;
  margin: 0 auto;
  background: #f6f6f8;
  .course-head {
    position: relative;
    background: #ffffff;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.08);
    padding: 20px 24px 40px;
    font-size: 14px;
    color: #000000;
    font-weight: 500;
    // margin: 20px 0;
    .course-info {
      margin-top: 20px;
      display: flex;
      .course-avatar {
        width: 200px;
        height: 200px;
        border-radius: 12px;
        margin-right: 32px;
      }
      .introduce {
        font-size: 14px;
        color: #57606a;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        p {
          margin-bottom: 17px;
        }
        p:last-child {
          margin-bottom: 0px;
        }
        .course-name {
          color: #1e252c;
          font-size: 18px;
          font-weight: 600;
        }
        .course-time {
          color: #f87e20;
        }
        .course-describe-title {
          width: 70px;
        }
        .course-describe {
          line-height: 35px;
          vertical-align: top;
          margin-top: -10px;
          flex: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .packUp {
          position: relative;
          line-height: 35px;
          vertical-align: top;
          margin-top: -10px;
          flex: 1;
        }
      }
    }
  }
}
.backdiv {
  position: fixed;
  cursor: pointer;
  background: #b8f4f7;
  width: 100px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}
.resumepad-style {
  position: absolute;
  top: 48px;
  right: 125px;
  z-index: 999;
}
.curpoin {
  cursor: pointer;
}
.box-card {
  margin: 0 0 10px 0;
}
</style>

<!--
   @Author:cyx
   @Date: 2023/12/26
   @Description:平台门户顶部轮播
 -->
<template>

  <swiper :options="swiperOption" @swiper="onSwiper" @slideChange="onSlideChange">
    <swiper-slide v-for="(item,index) in bannerList" :key="index">
      <a :href="item.jumpUrl" target="_blank">
        <img :src="item.picturePath" alt="" class="bannerImg">
      </a>
    </swiper-slide>
    <div slot="pagination" class="swiper-pagination"></div>
    <!-- <div slot="button-prev" class="swiper-button-prev"></div>
    <div slot="button-next" class="swiper-button-next"></div> -->
  </swiper>
</template>

<script>
import '@/components/swiper/swiper.css'
import bgimg from '@/assets/images/home.png'
export default {
  name: 'Swiperbanner',
  props: {
    dictCode: {
      type: String,
      default() {
        return ''
      }
    }
    // picList: {
    //   type: Array,
    //   default() {
    //     return [{ picturePath: bgimg }]
    //   }
    // },
    // loop: {
    //   type: Boolean,
    //   default() {
    //     return false
    //   }
    // }
  },
  data() {
    return {
      bannerList: [],
      // swiperOption: {
      //   initialSlide: 5,
      //   slidesPerView: 1,
      //   // 设置自动轮播
      //   autoplay: {
      //     stopOnLastSlide: false,
      //     disableOnInteraction: false,
      //     delay: 0
      //   },
      //   loopFillGroupWithBlank: true,
      //   normalizeSlideIndex: true,
      //   autoplayDisableOnInteraction: false,
      //   speed: 6000, // 匀速播放频率
      //   freeMode: true,
      //   // 设置轮播可循环
      //   loop: true
      //
      swiperOption: {
        watchOverflow: true,
        slidesPerView: 1,
        // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          // 设置点击可切换
          clickable: true
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        autoplay: {
          delay: 5000 // 5秒切换一次
        },
        // // 设置轮播可循环
        loop: true
      },
      dictObj: {}
    }
  },
  mounted() {
    this.requireBanners()
    this.getDictObj()
  },
  methods: {
    onSwiper() {

    },
    onSlideChange() {

    },
    // 获取banner
    requireBanners() {
      this.$get(this.urls.bannerList, null, [this.dictCode]).then(result => {
        if (result.code === 'SUCCESS') {
          if (result.data.length > 0) {
            this.bannerList = result.data
          } else {
            this.bannerList = [{ picturePath: bgimg }]
          }
        } else {
          this.$message.error(result.msg)
        }
      }).catch(err => {
        console.log('err==', err)
      })
    },
    // 获取字典数据
    getDictObj() {
      this.$get(`${this.urls.dicValues}`, {
        types: 'banner'
      }).then((result) => {
        if (result.code === 'SUCCESS') {
          this.dictObj.banner = result.data.banner
        }
      }).catch((e) => {
      })
    }
  }

}
</script>

<style lang="scss" scoped>
// ::v-deep .swiper-wrapper {
//   -webkit-transition-timing-function: linear; /*之前是ease-out*/
//   -moz-transition-timing-function: linear;
//   -ms-transition-timing-function: linear;
//   -o-transition-timing-function: linear;
//   transition-timing-function: linear;
//   margin: 0 auto;
// }
.bannerImg {
  width: 100%;
  height: 430px;
}
</style>

<!--
   User: Liu Yin
   Date: 2020/3/12
   Description:企业学院
 -->
<template>
  <article class="online-root">
    <section v-show="showDetail===1">
      <swiperbanner class="banner" :dict-code="Banner.BANNER_CLASSROOM"></swiperbanner>
      <!-- <div class="ceil">
        <el-menu :default-active="menuIndex" class="el-menu-demo" mode="horizontal" @select="menuSelect">
          <el-menu-item index="1">在线课程</el-menu-item>
          <el-menu-item index="2">名师介绍</el-menu-item>
        </el-menu>
      </div> -->

      <el-row v-if="menuIndex==='1'" :gutter="10" style="display: flex;align-items: center;justify-content: center;">
        <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="16">
          <div>
            <div class="flexcencen">
              <div class="container-course">
                <!-- <div class="container-left">
            <el-tree :data="data" :props="defaultProps" :default-expand-all="true" @node-click="handleNodeClick">
            </el-tree>
          </div> -->

                <el-card shadow="always" class="label_box">
                  <div>
                    <div class="directe">
                      <div class="field_direction">领域：</div>
                      <ul v-for="(item,index) in firstLevel" :key="index">
                        <li :class="item.atId===atId?'li_active':''" @click="queryType(item)">{{ item.cuName }}</li>
                      </ul>
                    </div>
                    <div>
                      <div class="field_direction">方向：</div>
                      <ul v-for="(item,index) in secondLevel" :key="index">
                        <li :class="item.atId===childrenId?'li_active':''" @click="queryType(item)">{{ item.cuName }}</li>
                      </ul>
                    </div>
                  </div>
                </el-card>
                <div style="position: relative;margin: 0 auto 40px;max-width: 1280px;" class="comprehensive">
                  <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                    <el-menu-item index="1">综合排序</el-menu-item>
                    <el-menu-item index="2">
                      <span slot="title" class="navTitle">人气</span>
                      <span :class="searchForm.rank==='hottest'?'active':''"><i class="el-icon-caret-top" @click.stop.prevent="handleSelectPopularity('2-1')"></i></span>
                      <span :class="searchForm.rank==='unpopular'?'active':''"><i class="el-icon-caret-bottom" @click.stop.prevent="handleSelectPopularity('2-2')"></i></span>
                    </el-menu-item>
                    <el-menu-item index="3">
                      <span slot="title" class="navTitle">最新</span>
                      <span :class="searchForm.rank==='latest'?'active':''"><i class="el-icon-caret-top" @click.stop.prevent="handleSelectPopularity('3-1')"></i></span>
                      <span :class="searchForm.rank==='oldest'?'active':''"><i class="el-icon-caret-bottom" @click.stop.prevent="handleSelectPopularity('3-2')"></i></span>
                    </el-menu-item>
                    <el-menu-item index="4">收费</el-menu-item>
                  </el-menu>
                  <el-input v-model="searchForm.keyWords" placeholder="搜索您感兴趣的课程" class="inputwidth" @change="requestData">
                    <i slot="suffix" class="el-icon-search"></i>
                  </el-input>
                </div>

                <div class="container-right">
                  <course-list v-if="records.length>0" :records="records" :total="total" :current-page="currentPage" @openCourse="handleOpenCourse" @changePage="handleChangePage"></course-list>
                  <el-empty v-else description="企业学院暂无课程数据"></el-empty>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row v-if="menuIndex==='2'" :gutter="10" style="display: flex;align-items: center;justify-content: center;">
        <el-col :xs="24" :sm="24" :md="24" :lg="20" :xl="16">
          <div>
            <div class="flexcencen">
              <div class="container-course">
                <!-- <el-card shadow="always" class="label_box">
                  <div>
                    <div class="directe">
                      <div class="field_direction">领域：</div>
                      <ul v-for="(item,index) in firstLevel" :key="index">
                        <li :class="item.atId===atId?'li_active':''" @click="queryType(item)">{{ item.cuName }}</li>
                      </ul>
                    </div>
                    <div>
                      <div class="field_direction">方向：</div>
                      <ul v-for="(item,index) in secondLevel" :key="index">
                        <li :class="item.atId===childrenId?'li_active':''" @click="queryType(item)">{{ item.cuName }}</li>
                      </ul>
                    </div>
                  </div>
                </el-card>
                <div style="position: relative;margin: 0 auto 40px;max-width: 1280px;" class="comprehensive">
                  <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                    <el-menu-item index="1">综合排序</el-menu-item>
                    <el-menu-item index="2">
                      <span slot="title" class="navTitle">人气</span>
                      <span :class="searchForm.rank==='hottest'?'active':''"><i class="el-icon-caret-top" @click.stop.prevent="handleSelectPopularity('2-1')"></i></span>
                      <span :class="searchForm.rank==='unpopular'?'active':''"><i class="el-icon-caret-bottom" @click.stop.prevent="handleSelectPopularity('2-2')"></i></span>
                    </el-menu-item>
                    <el-menu-item index="3">
                      <span slot="title" class="navTitle">最新</span>
                      <span :class="searchForm.rank==='latest'?'active':''"><i class="el-icon-caret-top" @click.stop.prevent="handleSelectPopularity('3-1')"></i></span>
                      <span :class="searchForm.rank==='oldest'?'active':''"><i class="el-icon-caret-bottom" @click.stop.prevent="handleSelectPopularity('3-2')"></i></span>
                    </el-menu-item>
                  </el-menu>
                  <el-input v-model="searchForm.keyWords" placeholder="搜索您喜爱的讲师" class="inputwidth" @change="requestData">
                    <i slot="suffix" class="el-icon-search"></i>
                  </el-input>
                </div> -->

                <div class="container-right">
                  <teacher-list v-if="teacherData.length>0" :records="teacherData" :total="total" :current-page="currentPage" @openTeacher="openTeacher" @changePage="handleChangeTeacherPage"></teacher-list>
                  <el-empty v-else description="企业学院暂无讲师数据"></el-empty>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </section>
    <detail v-if="showDetail===2" :row-data="itemCourse" @close="refresh"></detail>
    <teacher-detail v-if="showDetail===3" :item-teacher="itemTeacher" @close="refresh"></teacher-detail>
  </article>
</template>

<script>
import course1 from '../../../../assets/images/online/online-course-1.png'
import course2 from '../../../../assets/images/online/online-course-2.png'
import course3 from '../../../../assets/images/online/online-course-3.png'
import teacher1 from '../../../../assets/images/online/teacher-1.png'
import teacher2 from '../../../../assets/images/online/teacher-2.png'
import teacher3 from '../../../../assets/images/online/teacher-3.png'
import teacher4 from '../../../../assets/images/online/teacher-4.png'

// import teachModel from './components/teachModel'
import courseList from './components/courseList'
import teacherList from './components/teacherList'
import teacherDetail from './components/teacherDetails'

import listMixin from '../../../../utils/mixins/listMixin'
import detail from './detail'
import { Banner } from '../../../../utils/dict'
import Swiperbanner from '../../../../components/swiperbanner.vue'

export default {
  components: {
    // teachModel,
    courseList,
    teacherList,
    detail,
    Swiperbanner,
    teacherDetail
  },
  mixins: [listMixin],
  data() {
    return {
      Banner,
      showDetail: 1,
      itemCourse: {},
      itemTeacher: {},
      menuList: [
        {
          title: 'IT技能培训'
        },
        {
          title: '管理技能培训'
        },
        {
          title: '素质教育培训'
        }
      ],
      records: [],
      data: [{
        label: '通识课程',
        children: [
          { label: ' 职场软技能/产品/设计' }
        ]
      },
      {
        label: '专业课程',
        children: [
          { label: 'IT技术类' },
          { label: '生产制造类' }
        ]
      },
      {
        label: '营销课程'
      }
      ],
      defaultProps: {
        children: 'children',
        label: 'cuName'
      },
      courseList: [
        {
          icon: course1,
          title: '技能课',
          summary: '从入门到 进阶之旅',
          content: 'JAVA/C++/Python DLP/JSP/MDB/ Oracle/CCIE/UI'
        },
        {
          icon: course2,
          title: '管理课',
          summary: '汇聚管理 大师',
          content: '绩效管理/财务管理 HRBP/市场营销/SEO SEM/组织重组'
        },
        {
          icon: course3,
          title: '素质课',
          summary: '素质拓展 高薪起点',
          content: '组织协作/柔性管理 时间管理/心理学 高效沟通/性格色彩'
        }
      ],
      teacherList: [
        {
          icon: teacher1,
          name: '阎赫',
          courseName: 'Python课程专家',
          domain: 'CCAEE专家',
          introduction: '毕业于南开大学软件学院，擅长Python编程与项目顶层框架设计，教育部工程认证专家，15年系统管理和开发经验'
        },
        {
          icon: teacher2,
          name: '王晓华',
          courseName: 'JAVA课程专家',
          domain: 'CCAEE专家',
          introduction: '北京理工大学客座教授，教育部专家，在JAVA软件开发过程中有超过20年的开发和设计经验，曾参与国家重大IT项目建设'
        },
        {
          icon: teacher3,
          name: '杨\xa0\xa0强',
          courseName: '智能硬件专家',
          domain: 'CCAEE专家',
          introduction: '微软认证专家，擅长C语言编程与教育，教育部CCAEE专家，拥有超过18年的项目开发经验，在智能硬件开发领域经验丰富'
        },
        {
          icon: teacher4,
          name: '周海',
          courseName: '项目管理专家',
          domain: 'IEEE专家',
          introduction: 'PMP专家，项目管理专家，擅长项目组织架构设计与技术框架设计，拥有超过16年的项目管理经验，IEEE工程专家'
        }
      ],
      atId: null,
      childrenId: null,
      firstLevel: [],
      secondLevel: [],
      // 课程查询参数
      queryData: {
        atType: 1
      },
      activeIndex: '1',
      menuIndex: '1',
      atCode: '',
      teacherData: []

    }
  },
  // activated() {
  //   document.getElementById('app').scrollTop = 0
  // },
  created() {
    document.body.scrollTop = 0
    this.requestData()
    this.requestType()
  },
  methods: {
    handleSelectPopularity(val) {
      if (val === '2-1') {
        this.searchForm.rank = 'hottest'
        this.activeIndex = '2'
      } else if (val === '2-2') {
        this.searchForm.rank = 'unpopular'
        this.activeIndex = '2'
      } else if (val === '3-1') {
        this.activeIndex = '3'
        this.searchForm.rank = 'latest'
      } else if (val === '3-2') {
        this.activeIndex = '3'
        this.searchForm.rank = 'oldest'
      }
      this.currentPage = 1
      this.$forceUpdate()
      this.requestData()
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath)
      if (key === '1') {
        this.searchForm.rank = ''
      } else if (key === '2') {
        this.searchForm.rank = 'hottest'
      } else if (key === '3') {
        this.searchForm.rank = 'latest'
      } else if (key === '4') {
        this.searchForm.rank = 'charge_latest'
      }
      this.currentPage = 1
      this.$forceUpdate()
      this.requestData()
    },
    menuSelect(key, keyPath) {
      this.menuIndex = key
      if (key === '1') {
        // 课程
        this.requestData()
      } else if (key === '2') {
        // 教师
        this.requestTeacherData()
      }
    },
    requestData() {
      this.$get(this.urls.courseList, this.searchForm, [this.currentPage, 12]).then(result => {
        if (result.code === 'SUCCESS') {
          const data = result.data
          data.records.forEach(item => {
            item.discountedPrice = parseInt(Number(item.discountedPrice))
            item.price = parseInt(Number(item.price))
          })
          this.records = data.records
          this.total = data.total
        } else {
          this.$message.error(result.msg)
        }
      }).catch(err => {
        console.log('err==', err)
      })
    },
    requestTeacherData() {
      this.$get(this.urls.teacherList, this.searchForm, [this.currentPage, 12]).then(result => {
        if (result.code === 'SUCCESS') {
          const data = result.data
          data.records.forEach(item => {
            item.discountedPrice = parseInt(Number(item.discountedPrice))
            item.price = parseInt(Number(item.price))
            item.tagArray = []
            if (item.tags) {
              const tagList = item.tags.split(',')
              tagList.forEach(tag => {
                item.tagArray.push({ value: tag })
              })
            }
          })

          this.teacherData = data.records
          console.log(this.teacherData, 'this.teacherData')
          this.total = data.total
        } else {
          this.$message.error(result.msg)
        }
      }).catch(err => {
        console.log('err==', err)
      })
    },
    // 查询课程类型1/2级方法
    queryType(row) {
      // if (row.atPid === 0) {
      if (!row.atPid) {
        this.queryData.atPid = row.atId
        this.atId = row.atId
        this.searchForm.domain = row.atCode
        this.searchForm.direction = ''

        this.requestType()
        this.requestData()
        this.atCode = row.atCode
      } else {
        if (row.atId) {
          this.searchForm.domain = ''
          this.searchForm.direction = row.atCode
        } else {
          this.searchForm.direction = ''
          this.searchForm.domain = this.atCode
        }
        this.childrenId = row.atId
        console.log(row.atPid, this.queryData.atPid)
        if (row.atPid === this.queryData.atPid || !this.queryData.atPid) {
          this.atId = row.atPid
        }
        this.requestData()
      }
      console.log(row)
    },
    // 返回刷新
    refresh() {
      this.showDetail = 1
    },
    handleChangePage(page) {
      this.currentPage = page
      this.requestData()
    },
    handleChangeTeacherPage(page) {
      this.currentPage = page
      this.requestTeacherData()
    },
    // 获取课程分类&一二级
    requestType() {
      this.$get('/portal/getDomainType', this.queryData).then(result => {
        this.data = result.data
        this.secondLevel = []
        this.secondLevel.unshift({ cuName: '全部', atPid: this.queryData.atPid, atId: 0 })

        if (this.queryData.atPid === 0 || !this.queryData.atPid) {
          this.firstLevel = []
          this.firstLevel.unshift({ cuName: '全部', atPid: 0, atId: 0 })
        }
        this.data.forEach(item => {
          if (item.atPid === 0 && !this.searchForm.atId) {
            this.firstLevel.push(item)
          } else {
            this.secondLevel.push(item)
          }
        })
        if (!this.searchForm.cuType) {
          this.childrenId = 0
        }
        if (!this.queryData.atPid) {
          this.atId = 0
        }
      })
    },
    handleNodeClick(row) {
      this.searchForm.cuNature = row.atCode
      this.requestData()
    },
    // toCourse() {
    //   const targetElement = document.getElementById('target-element')
    //   targetElement.scrollIntoView({
    //     behavior: 'smooth' // 可选配置，控制是否平滑滚动
    //   })
    //   this.$router.push('/online-m/course')
    // },
    handleOpenCourse(item) {
      console.log(item)

      this.$get(this.urls.addClickCount, null, [item.cuId]).then(result => { })
      this.itemCourse = item
      this.showDetail = 2
    },
    openTeacher(item) {
      console.log(item)
      this.$get(this.urls.teacherGet, null, [item.id]).then(result => { })
      this.itemTeacher = item
      this.showDetail = 3
    }
  }
}
</script>

<style lang="scss" scoped>
.online-root {
  // overflow-x: hidden;
  // width: 67%;

  margin: auto;
}
.label_box {
  max-width: 1280px;
  border-radius: 8px;
  margin: 24px auto 40px;
  width: 100%;
  ul {
    display: inline-block;
    li {
      box-sizing: border-box;
      margin: 0 8px 8px 0;
      padding: 6px 8px;
      color: #4e5969;
      font-size: 16px;
      line-height: 20px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .li_active {
    color: #2c6efb;
    background-color: #e8f3ff;
  }
}

.field_direction {
  display: inline-block;
}
.directe {
  margin-bottom: 10px;
}
.flexcencen {
  // display: flex;
  //   align-items: center;
  //   justify-content: center;
}

.inputwidth {
  position: relative;
  width: 43%;
  position: absolute;
  top: 25px;
  right: 20px;
}

::v-deep .el-input__suffix {
  position: absolute;
  top: 12px !important;
}
.el-icon-caret-bottom:before {
  content: "\e790";
  position: absolute;
  top: 23px;
  right: 8px;
}
.el-icon-caret-top:before {
  content: "\e78f";
  position: absolute;
  top: 15px;
  right: 8px;
}
.navTitle {
  position: absolute;
  top: 6px;
  left: 10px;
}
.active {
  .el-icon-caret-top:before {
    content: "\e78f";
    position: absolute;
    top: 15px;
    right: 8px;
    color: #1989fa;
  }
  .el-icon-caret-bottom:before {
    content: "\e790";
    position: absolute;
    top: 23px;
    right: 8px;
    color: #1989fa;
  }
}
.container-right {
  margin: 0 auto;
}
.ceil {
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 1px 5px 0 rgb(0 0 0 / 10%);
  background: #fff;
  .el-menu-demo {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ::v-deep .el-menu--horizontal > .el-menu-item.is-active {
    border-bottom: 3px solid #409eff;
    color: #303133;
    font-weight: 900;
    font-size: 16px;
  }
}

.comprehensive {
  .el-menu-demo {
    padding: 12px 20px;
    border-radius: 8px;
  }
  .el-menu.el-menu--horizontal {
    width: 100%;
  }
  .el-menu--horizontal > .el-menu-item.is-active {
    font-weight: bold;
  }
  .el-menu--horizontal > .el-menu-item {
    font-size: 18px;
    line-height: 48px;
  }
  ul {
    display: inline-block;
    li {
      box-sizing: border-box;
      margin: 0 8px 8px 0;
      padding: 6px 8px;
      color: #4e5969;
      font-size: 16px;
      line-height: 20px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .li_active {
    color: #2c6efb;
    background-color: #e8f3ff;
  }
}
.el-row {
  padding-left: 0;
  padding-right: 0;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
</style>

import { render, staticRenderFns } from "./teacherDetails.vue?vue&type=template&id=cc60426a&scoped=true&"
import script from "./teacherDetails.vue?vue&type=script&lang=js&"
export * from "./teacherDetails.vue?vue&type=script&lang=js&"
import style0 from "./teacherDetails.vue?vue&type=style&index=0&id=cc60426a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc60426a",
  null
  
)

export default component.exports